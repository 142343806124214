@use 'shared/shared';

/* NOTE: Select doesn't have certain variables - these imported as are fallbacks. */
@use '~@nbcnews/design-tokens/tokens/nbcnews/breakpoints' as fallback_breakpoints;
@use '~@nbcnews/design-tokens/tokens/nbcnews/colors' as fallback_colors;
@use '~@nbcnews/design-tokens/tokens/nbcnews/colorsRGB' as fallback_colorsRGB;
@use '~@nbcnews/design-tokens/tokens/nbcnews/fontFamilies' as fallback_fontFamilies;
@use '~@nbcnews/design-tokens/tokens/nbcnews/fontSizes' as fallback_fontSizes;
@use '~@nbcnews/design-tokens/tokens/nbcnews/fontWeights' as fallback_fontWeights;
@use '~@nbcnews/design-tokens/tokens/nbcnews/gradients' as fallback_gradients;
@use '~@nbcnews/design-tokens/tokens/nbcnews/letterSpacings' as fallback_letterSpacings;
@use '~@nbcnews/design-tokens/tokens/nbcnews/lineHeights' as fallback_lineHeights;
@use '~@nbcnews/design-tokens/tokens/nbcnews/opacities' as fallback_opacities;
@use '~@nbcnews/design-tokens/tokens/nbcnews/spacing' as fallback_spacing;

/* END FALLBACKS */
@use '~@nbcnews/design-tokens/tokens/select/breakpoints';
@use '~@nbcnews/design-tokens/tokens/select/colors';
@use '~@nbcnews/design-tokens/tokens/select/colorsRGB';
@use '~@nbcnews/design-tokens/tokens/select/fontFamilies';
@use '~@nbcnews/design-tokens/tokens/select/fontSizes';
@use '~@nbcnews/design-tokens/tokens/select/fontWeights';
@use '~@nbcnews/design-tokens/tokens/select/gradients';
@use '~@nbcnews/design-tokens/tokens/select/letterSpacings';
@use '~@nbcnews/design-tokens/tokens/select/lineHeights';
@use '~@nbcnews/design-tokens/tokens/select/opacities';
@use '~@nbcnews/design-tokens/tokens/select/spacing';

// GLOBAL VARIABLES
$theme: 'select';
$black: #000;

// Background
$default-background: colors.$white;

// Colors
$default-color: colors.$blue-70;
$default-neutral: colors.$grey-30;

// Gradient
$default-gradient: to-list(fallback_gradients.$blue-blue-gradient);

// Images
$default-img-border-radius: 0%;

// Links
$default-hover-opacity: opacities.$opacity-70;
$default-active-opacity: opacities.$opacity-50;

// Headlines
$default-headline-color: colors.$grey-70;
$default-headline-leading: lineHeights.$leading-150;
$default-headline-font-family: fontFamilies.$lato;

// Body text
$default-text-size: fontSizes.$text-18;
$default-text-color: colors.$grey-70;
$default-text-leading: lineHeights.$leading-150;
$default-text-font-family: fontFamilies.$lato;

// Background
$default-background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxyZWN0IHg9IjE5LjA5OTYiIHk9Ii0yNC4wNzI2IiB3aWR0aD0iMiIgaGVpZ2h0PSI0MyIgdHJhbnNmb3JtPSJyb3RhdGUoMzUgMTkuMDk5NiAtMjQuMDcyNikiIGZpbGw9IiNDM0M4Q0QiLz4KPHJlY3QgeD0iMTEuMDk5NiIgeT0iLTQuMDcyNiIgd2lkdGg9IjIiIGhlaWdodD0iNDMiIHRyYW5zZm9ybT0icm90YXRlKDM1IDExLjA5OTYgLTQuMDcyNikiIGZpbGw9IiNDM0M4Q0QiLz4KPHJlY3QgeD0iMTciIHk9Ii00IiB3aWR0aD0iMiIgaGVpZ2h0PSI0MyIgdHJhbnNmb3JtPSJyb3RhdGUoMzUgMTcgLTQpIiBmaWxsPSIjQzNDOENEIi8+CjxyZWN0IHg9IjIxLjY2NDEiIHk9Ii0yLjA3MjYiIHdpZHRoPSIyIiBoZWlnaHQ9IjQzIiB0cmFuc2Zvcm09InJvdGF0ZSgzNSAyMS42NjQxIC0yLjA3MjYpIiBmaWxsPSIjQzNDOENEIi8+CjxyZWN0IHg9IjI5LjA5OTYiIHk9Ii00LjA3MjYiIHdpZHRoPSIyIiBoZWlnaHQ9IjQzIiB0cmFuc2Zvcm09InJvdGF0ZSgzNSAyOS4wOTk2IC00LjA3MjYpIiBmaWxsPSIjQzNDOENEIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');

:root {
  /* GLOBAL VARIABLES */
  --black: #000;

  /* Background */
  --default-background: var(--white);

  /* Colors */
  --default-color: var(--blue-70);
  --default-color-rgb: var(--blue-70-rgb);
  --default-neutral: var(--grey-30);
  --default-neutral-rgb: var(--grey-30-rgb);
  --primary-color: var(--default-color);
  --primary-color-rgb: var(--default-color-rgb);

  /* Gradient */
  --default-gradient: var(--blue-blue-gradient-stop-1), var(--blue-blue-gradient-stop-2);

  /* Images */
  --default-img-border-radius: 0%;

  /* Links */
  --default-hover-opacity: var(--opacity-70);
  --default-active-opacity: var(--opacity-50);

  /* Headlines */
  --default-headline-color: var(--grey-70);
  --default-headline-color-rgb: var(--grey-70-rgb);
  --default-headline-leading: var(--leading-150);
  --default-headline-font-family: var(--lato);
  --primary-font: var(--default-headline-font-family);

  /* Body text */
  --default-text-size: var(--text-18);
  --default-text-color: var(--grey-70);
  --default-text-color-rgb: var(--grey-70-rgb);
  --default-text-leading: var(--leading-150);
  --default-text-font-family: var(--lato);
  --secondary-font: var(--default-text-font-family);

  /* Background */
  --default-background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxyZWN0IHg9IjE5LjA5OTYiIHk9Ii0yNC4wNzI2IiB3aWR0aD0iMiIgaGVpZ2h0PSI0MyIgdHJhbnNmb3JtPSJyb3RhdGUoMzUgMTkuMDk5NiAtMjQuMDcyNikiIGZpbGw9IiNDM0M4Q0QiLz4KPHJlY3QgeD0iMTEuMDk5NiIgeT0iLTQuMDcyNiIgd2lkdGg9IjIiIGhlaWdodD0iNDMiIHRyYW5zZm9ybT0icm90YXRlKDM1IDExLjA5OTYgLTQuMDcyNikiIGZpbGw9IiNDM0M4Q0QiLz4KPHJlY3QgeD0iMTciIHk9Ii00IiB3aWR0aD0iMiIgaGVpZ2h0PSI0MyIgdHJhbnNmb3JtPSJyb3RhdGUoMzUgMTcgLTQpIiBmaWxsPSIjQzNDOENEIi8+CjxyZWN0IHg9IjIxLjY2NDEiIHk9Ii0yLjA3MjYiIHdpZHRoPSIyIiBoZWlnaHQ9IjQzIiB0cmFuc2Zvcm09InJvdGF0ZSgzNSAyMS42NjQxIC0yLjA3MjYpIiBmaWxsPSIjQzNDOENEIi8+CjxyZWN0IHg9IjI5LjA5OTYiIHk9Ii00LjA3MjYiIHdpZHRoPSIyIiBoZWlnaHQ9IjQzIiB0cmFuc2Zvcm09InJvdGF0ZSgzNSAyOS4wOTk2IC00LjA3MjYpIiBmaWxsPSIjQzNDOENEIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
}
